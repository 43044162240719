import React from 'react';
import styled from 'styled-components';
import { space, layout, color, flexbox } from 'styled-system';
import { Spring } from 'react-spring/renderprops';

export const Card = styled.div`
  background: ${props => props.bg || '#36516C'};
  ${props =>
    props.bgRight && {
      backgroundImage: `linear-gradient(to right, ${props.bg}, ${
        props.bgRight
      })`,
    }}
  padding: 0 32px;
  border-radius: 16px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 2px rgba(0,0,0,0.2);
  overflow: hidden;
  position: relative;
  font-family: 'Boston';
  color: white;

  ${space}
  ${layout}
  ${color}
  ${flexbox}

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    height: 84%;
    top: 8%;
    width: 10px;
    border-radius: 0 5px 5px 0;
    background: ${props => props.border || 'transparent'};
    ${props =>
      props.bgRight && {
        backgroundImage: `linear-gradient(to right, ${props.border}, ${
          props.borderTop
        })`,
      }}
  }

  @media screen and (min-width: 1025px) {
    height: 250px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 488px;
  }
`;

export const AnimatedCard = ({ children, animation, ...props }) => (
  <Spring
    from={{ transform: 'translate3d(0,40px, 0)', opacity: 0 }}
    to={{ transform: 'translate3d(0,0px, 0)', opacity: 1 }}
    config={{ tension: 30, friction: 10 }}
    {...animation}
  >
    {styles => (
      <Card style={styles} {...props}>
        {children}
      </Card>
    )}
  </Spring>
);

export default AnimatedCard;
