/*
 * action types
 */

export const FEEDBACK_SHOW = 'FEEDBACK_SHOW';
export const FEEDBACK_SENT = 'FEEDBACK_SENT';
export const THEME_SET_MODE = 'THEME_MODE_CHANGE';
export const USER_SET = 'USER_SET';
export const FILTERS_SET = 'FILTERS_SET';
export const FILTERS_RESET = 'FILTERS_RESET';

/*
 * action creators
 */

export function showFeedback(opened) {
  return { type: FEEDBACK_SHOW, opened };
}

export function sendFeedback(feedback) {
  return { type: FEEDBACK_SHOW, feedback };
}

export function changeThemeMode(mode) {
  return { type: THEME_SET_MODE, mode };
}

export function setUser(user) {
  return { type: USER_SET, user };
}

export function setFilter(filter) {
  return { type: FILTERS_SET, filter };
}

export function resetFilters() {
  return { type: FILTERS_RESET };
}
