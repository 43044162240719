import React from 'react';
import styled from 'styled-components';
import { Box } from '../../components/Base';

const HighlightBox = styled(Box)`
  background: #3c5c7a;
  border-radius: 18px;
  color: ${props => props.color};
  font-size: 16px;
  line-height: 1;
  align-items: center;
  padding: 16px 12px;
  background: #3c5c7a;
  box-shadow: 1px 1px 9px 2px rgba(0, 0, 0, 0.09);
  position: relative;

  .goal {
    background: #436582;
    border-radius: 8px;
    font-size: 15px;
    color: white;
    margin: 16px 0;

    .title {
      color: #57889e;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: -0.8px;
    }
  }

  .stats {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    margin: 10px auto 0;
    padding-top: 6px;
  }
`;

HighlightBox.defaultProps = {
  color: '#55c6ff',
};

export default HighlightBox;
