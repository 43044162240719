import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Flex } from '../../components/Base';

import { ReactComponent as TrendArrow } from '../../icons/indicator.svg';

const Trend = ({ theme, value }) => (
  <Flex
    height="24px"
    width="24px"
    alignItems="center"
    style={{ background: '#2F4A60', borderRadius: 6 }}
  >
    {value === 0 ? (
      '-'
    ) : (
      <TrendArrow
        height={8}
        style={{
          top: -1,
          position: 'relative',
          display: 'inline-block',
          color: value > 0 ? theme.colors.green.light : theme.colors.red.dark,
          transform: value < 0 && 'rotate(180deg)',
        }}
      />
    )}
  </Flex>
);

export default withTheme(Trend);
