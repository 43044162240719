import ReactGA from "react-ga";

const EVENT_CLICK = "CLICK";

function getUserId() {
  if (localStorage.getItem("username") === null) {
    return localStorage.getItem("username");
  }
}

function init() {
  // previous track id UA-143251019-2
  ReactGA.initialize("UA-165379278-1", {
    cookieDomain: 'none',
    debug: true
  });
}

function setUser() {
  ReactGA.set({ userId: getUserId() });
}

function sendEvent(payload) {
  ReactGA.event({
    ...payload
  });
}

export default {
  EVENT_CLICK,
  init,
  sendEvent,
  setUser
};
