import React from 'react';
import styled from 'styled-components';
import { Text } from '../../components/Base';

export const Diff = styled(Text)`
  color: ${props => (props.negative ? '#fc5c1f' : '#00ff00')};
`;

const BoxStyle = styled(Text)`
  display: inline-block;
  border-radius: 10px;
  padding: 0.85em 0.65em;
  background: #2f4a60;
  letter-spacing: 0.5px;
`;

const percentage = number =>
  new Intl.NumberFormat('es-AR', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

export const DiffNumber = ({ number, negative, ...rest }) => (
  <Diff negative={number < 0} {...rest}>
    {number > 0 ? '+' : '-'}
    {percentage(Math.abs(number))}
  </Diff>
);

export const DiffBox = ({ number, negative, ...rest }) => (
  <BoxStyle negative={number < 0} {...rest}>
    <DiffNumber number={number} />
  </BoxStyle>
);
