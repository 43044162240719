import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  slideIn,
  fadeInUp,
  fadeOutDown,
  slideOut,
  merge,
} from 'react-animations';
import ReactGA from 'react-ga';
import styled, { keyframes } from 'styled-components';
import { CloseO as CloseIcon } from 'styled-icons/evil/CloseO';
import { Star as StarIcon } from 'styled-icons/typicons/Star';
import { Dislike as DislikeIcon } from 'styled-icons/boxicons-regular/Dislike';
import { Like as LikeIcon } from 'styled-icons/boxicons-regular/Like';
import { SmileWink as SmileIcon } from 'styled-icons/fa-regular/SmileWink';

import { showFeedback } from '../actions';

const slideFadeInAnimation = keyframes`${merge(fadeInUp, slideIn)}`;
const slideFadeOutAnimation = keyframes`${merge(fadeOutDown, slideOut)}`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  ::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background: rgba(25, 25, 25, 0.6);
    width: 100%;
    height: 100%;
  }

  button {
    appearance: none;
    background: none;
    border: none;
    outline: 0;
    padding: 0;
  }
`;

const BouncyDiv = styled.div`
  animation: 0.5s
    ${props => (props.open ? slideFadeInAnimation : slideFadeOutAnimation)};
  border-radius: 24px;
  background: #fafafa;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 2rem 1rem;
  box-sizing: border-box;
  box-shadow: 0 8px 12px rgba(55, 55, 55, 0.1);
  text-align: center;
  position: relative;
  color: #232425;

  width: 320px;

  button.close {
    position: absolute;
    right: 24px;
    top: 24px;
    border: 0;
    appearance: none;
    background: none;
  }

  svg {
    display: inline-block;
    vertical-align: baseline;
  }
`;

const Rating = styled.div`
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  padding: 1.2rem 0.6rem 0.6rem;
  text-align: center;
  margin: 12px 0;
  width: 100%;
`;

const Boolean = styled.div`
  background: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  padding: 1.2rem 0.6rem 0.6rem;
  text-align: center;
  margin: 12px 0;
  width: 100%;

  button {
    appearance: none;
    background: none;
    border: none;
    color: #c9c9c9;
    background: #f5f5f5;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 1rem;
    width: 50px;
    height: 50px;
    margin: 12px;
    outline: 0;

    &.active {
      &.dislike {
        color: #f63f65;
        background: #fcd5dd;
      }
      &.like {
        color: #20c8c0;
        background: #c2f0ed;
      }
    }
  }
`;

// const BouncyDiv = ({ children }) => <animated.div>{children}</animated.div>;

export default function Feedback() {
  const dispatch = useDispatch();
  const isOpened = useSelector(state => state.feedback.opened);
  const [feedback, setFeedback] = useState({
    stars: null,
    recommend: null,
    use: null,
  });

  useEffect(() => {
    if (feedback.stars && feedback.recommend && feedback.use) {
      const username = localStorage.getItem('username');
      const phone = localStorage.getItem('email');

      console.log('Send event');

      ReactGA.event({
        category: 'Feedback',
        action: JSON.stringify({
          phone,
          username,
          feedback,
        }),
        value: 1,
      });
      dispatch(showFeedback(false));
    }
  }, [feedback]);

  // const transitions = useTransition(isOpened, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 }
  // });

  const handleChange = (field, value) => {
    setFeedback({ ...feedback, [field]: value });
  };

  const onClose = () => {
    dispatch(showFeedback(false));
  };

  return (
    isOpened && (
      <Container>
        <BouncyDiv open={isOpened}>
          <button className="close" onClick={onClose}>
            <CloseIcon color="#ddd" size="20" />
          </button>
          <p
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              verticalAlign: 'middle',
              margin: '-50px 0 24px',
            }}
          >
            <span
              style={{
                background: '#fafafa',
                borderRadius: '50%',
                padding: '1rem',
                display: 'inline-block',
                width: '64px',
                height: '64px',
                boxSizing: 'border-box',
              }}
            >
              <SmileIcon color="#4CEDB0" />
            </span>
            <span
              style={{
                fontSize: 18,
                fontWeight: 600,
                letterSpacing: '-0.2px',
              }}
            >
              ¡Nos interesa tu opinión!
            </span>
          </p>
          <Rating>
            ¿Como calificás la experiencia?
            <div
              style={{
                marginTop: 16,
                fontSize: 18,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <button onClick={() => handleChange('stars', 1)}>
                <StarIcon
                  color={feedback.stars >= 1 ? '#FEC83C' : '#DCDDE1'}
                  size="32"
                  style={{ marginRight: 8 }}
                />
              </button>
              <button onClick={() => handleChange('stars', 2)}>
                <StarIcon
                  color={feedback.stars >= 2 ? '#FEC83C' : '#DCDDE1'}
                  size="32"
                  style={{ marginRight: 8 }}
                />
              </button>
              <button onClick={() => handleChange('stars', 3)}>
                <StarIcon
                  color={feedback.stars >= 3 ? '#FEC83C' : '#DCDDE1'}
                  size="32"
                  style={{ marginRight: 8 }}
                />
              </button>
              <button onClick={() => handleChange('stars', 4)}>
                <StarIcon
                  color={feedback.stars >= 4 ? '#FEC83C' : '#DCDDE1'}
                  size="32"
                  style={{ marginRight: 8 }}
                />
              </button>
              <button onClick={() => handleChange('stars', 5)}>
                <StarIcon
                  color={feedback.stars >= 5 ? '#FEC83C' : '#DCDDE1'}
                  size="32"
                  style={{ marginRight: 8 }}
                />
              </button>
            </div>
          </Rating>
          <Boolean>
            ¿Usarías Pandox en tu local?
            <div className="boolean">
              <button
                className={cx({ active: feedback.use === 1 }, 'dislike')}
                onClick={() => handleChange('use', 1)}
              >
                <DislikeIcon style={{ fontSize: 20 }} />
              </button>
              <button
                className={cx({ active: feedback.use === 2 }, 'like')}
                onClick={() => handleChange('use', 2)}
              >
                <LikeIcon style={{ fontSize: 20 }} />
              </button>
            </div>
          </Boolean>
          <Boolean>
            ¿Recomendarías a otros locales usar Pandox?{' '}
            <div className="boolean">
              <button
                className={cx({ active: feedback.recommend === 1 }, 'dislike')}
                onClick={() => handleChange('recommend', 1)}
              >
                <DislikeIcon style={{ fontSize: 20 }} />
              </button>
              <button
                className={cx({ active: feedback.recommend === 2 }, 'like')}
                onClick={() => handleChange('recommend', 2)}
              >
                <LikeIcon style={{ fontSize: 20 }} />
              </button>
            </div>
          </Boolean>
        </BouncyDiv>
      </Container>
    )
  );
}
