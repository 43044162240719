const setSession = date => +new Date(date.getTime() + 5 * 60000);

const isAuthenticated = () => {
  const exp = localStorage.getItem('login');
  return exp < +new Date() ? false : true;
};

export default {
  isAuthenticated: true, //isAuthenticated(),
  authenticate(cb) {
    this.isAuthenticated = true;
    localStorage.setItem('login', setSession(new Date()));
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};
