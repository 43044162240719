import React from 'react';
import PrototypeWrapper from './Wrapper';
import { Box, Text, Flex } from '../components/Base';
import Card from '../components/Card';
import CardTitle from './components/CardTitle';
import { ReactComponent as GoalsIcon } from '../icons/goals.svg';
import { ReactComponent as UnitKg } from '../icons/unit-kg.svg';
import { ReactComponent as CouponIcon } from '../icons/coupon.svg';
import { ReactComponent as TrendIcon } from '../icons/trend.svg';
import { ReactComponent as ClubIcon } from '../icons/club.svg';
import Thermometer from './components/Thermometer';
import { TableList, TableItem } from './components/TableList';
import ProgressBar from './components/ProgressBar';
import Trend from './components/Trend';
import TrendLine from './components/TrendLine';
import HighlightBox from './components/HighlightBox';
import { DiffBox } from './components/Diff';

import { Button, Radio, Icon } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

const trendData = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 6800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 5000,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 7500,
    amt: 2100,
  },
];

const trendDataNegative = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 3398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 2800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 1800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 1800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 1100,
    amt: 2100,
  },
];

const Goals = () => (
  <PrototypeWrapper>
    <Box className="boxes">
      <Card border="#CC54D3" animation={{ delay: 0 }}>
        <CardTitle>
          <span className="icon">
            <GoalsIcon style={{ height: 13, color: '#EA83FF' }} />
          </span>
          Objetivos
        </CardTitle>
        <Flex justifyContent="space-between" width={0.9} mt={3}>
          <Flex width={0.45} justifyContent="center">
            <Thermometer progress={85} />
          </Flex>
          <Flex width={[0.81, 0.81, 0.81, 0.81, 0.55]} alignItems="center">
            <Box
              style={{ display: 'inline-block', position: 'relative' }}
              ml={64}
              mr={32}
            >
              <Text
                fontSize="16px"
                color="#748599"
                lineHeight={1}
                style={{
                  borderBottom: '1px solid rgba(255,255,255,0.08)',
                  width: '100%',
                  textAlign: 'right',
                  paddingBottom: 3,
                  marginBottom: -12,
                }}
              >
                Kilos
              </Text>
              <Text fontSize={80} lineHeight={1.4} textAlign="center">
                85%
              </Text>
            </Box>
            <DiffBox fontSize={14} number={5} />
          </Flex>
        </Flex>
      </Card>
      <Card border="#CC54D3" animation={{ delay: 200 }}>
        <CardTitle>
          <span className="icon">
            <GoalsIcon style={{ height: 13, color: '#EA83FF' }} />
          </span>
          Objetivos
        </CardTitle>
        <Flex justifyContent="space-around" width={1} mt="16px">
          <HighlightBox color="#EA83FF">
            <Text color="#EA83FF" fontSize={14} textAlign="center">
              <UnitKg width={20} height={24} />
              <Text mt={1}>Kilos</Text>
            </Text>
            <Box className="goal" mt={2} p={2}>
              <Text className="title">Objetivo esperado</Text>
              <Text textAlign="center" mt={1}>
                450
              </Text>
            </Box>
            <ProgressBar progress={85} labelTop />
          </HighlightBox>
          <HighlightBox color="#EA83FF">
            <Text color="#EA83FF" fontSize={14} textAlign="center">
              <CouponIcon width={20} height={24} />
              <Text mt={1}>Promo</Text>
            </Text>
            <Box className="goal" mt={2} p={2}>
              <Text className="title">Objetivo esperado</Text>
              <Text textAlign="center" mt={1}>
                20
              </Text>
            </Box>
            <ProgressBar progress={110} labelTop />
          </HighlightBox>
          <HighlightBox color="#EA83FF">
            <Text color="#EA83FF" fontSize={14} textAlign="center">
              <TrendIcon width={20} height={24} />
              <Text mt={1}>Sobreventa</Text>
            </Text>
            <Box className="goal" mt={2} p={2}>
              <Text className="title">Objetivo esperado</Text>
              <Text textAlign="center" mt={1}>
                80
              </Text>
            </Box>
            <ProgressBar progress={30} labelTop />
          </HighlightBox>
          <HighlightBox color="#EA83FF">
            <Text color="#EA83FF" fontSize={14} textAlign="center">
              <ClubIcon
                width={24}
                height={28}
                strokeWidth="0.8px"
                stroke="currentColor"
              />
              <Text mt={1}>Ventas Club</Text>
            </Text>
            <Box className="goal" mt={2} p={2}>
              <Text className="title">Objetivo esperado</Text>
              <Text textAlign="center" mt={1}>
                450
              </Text>
            </Box>
            <ProgressBar progress={60} labelTop />
          </HighlightBox>
        </Flex>
      </Card>
      <Card border="#CC54D3" animation={{ delay: 400 }}>
        <CardTitle>
          <span className="icon">
            <GoalsIcon style={{ height: 13, color: '#EA83FF' }} />
          </span>
          Colaboradores destacados
        </CardTitle>
        {/* <div
          style={{
            position: 'absolute',
            top: 8,
            right: 32,
            display: 'flex',
            fontSize: 12,
          }}
        >
          <Radio.Group value={'large'} style={{ fontSize: 12 }}>
            <Radio.Button value="large">Large</Radio.Button>
            <Radio.Button value="default">Default</Radio.Button>
            <Radio.Button value="small">Small</Radio.Button>
          </Radio.Group>
        </div> */}
        <TableList mt={24} width={0.9}>
          <TableItem>
            <Flex alignItems="center">
              <Box className="avatar">
                <img src="/static/avatar.png" />
              </Box>
              <Box ml={2} style={{ width: 64, textOverflow: 'ellipsis' }}>
                Martín
              </Box>
            </Flex>
            <ProgressBar width={230} progress={102} />
            <Flex alignItems="center">
              <Text color="#5A93A7" fontSize={12} mr={2}>
                Tendencia
              </Text>
              <TrendLine data={trendData} />
            </Flex>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Box className="avatar">
                <img src="/static/avatar.png" />
              </Box>
              <Box ml={2} style={{ width: 64, textOverflow: 'ellipsis' }}>
                Esteban
              </Box>
            </Flex>
            <ProgressBar width={230} progress={85} />
            <Flex alignItems="center">
              <Text color="#5A93A7" fontSize={12} mr={2}>
                Tendencia
              </Text>
              <TrendLine data={trendData} />
            </Flex>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Box className="avatar">
                <img src="/static/avatar.png" />
              </Box>
              <Box ml={2} style={{ width: 64, textOverflow: 'ellipsis' }}>
                Francisco
              </Box>
            </Flex>
            <ProgressBar width={230} progress={66} />
            <Flex alignItems="center">
              <Text color="#5A93A7" fontSize={12} mr={2}>
                Tendencia
              </Text>
              <TrendLine data={trendData} />
            </Flex>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Box className="avatar">
                <img src="/static/avatar.png" />
              </Box>
              <Box ml={2} style={{ width: 64, textOverflow: 'ellipsis' }}>
                Tomás
              </Box>
            </Flex>
            <ProgressBar width={230} progress={66} />
            <Flex alignItems="center">
              <Text color="#5A93A7" fontSize={12} mr={2}>
                Tendencia
              </Text>
              <TrendLine data={trendData} />
            </Flex>
          </TableItem>
        </TableList>
      </Card>
      <Card border="#CC54D3" animation={{ delay: 600 }}>
        <CardTitle>
          <span className="icon">
            <GoalsIcon style={{ height: 13, color: '#EA83FF' }} />
          </span>
          Colaboradores críticos
        </CardTitle>
        <TableList mt={24} width={0.9}>
          <TableItem>
            <Flex alignItems="center">
              <Box className="avatar">
                <img src="/static/avatar.png" />
              </Box>
              <Box ml={2} style={{ width: 64, textOverflow: 'ellipsis' }}>
                Sofía
              </Box>
            </Flex>
            <ProgressBar width={230} progress={34} />
            <Flex alignItems="center">
              <Text color="#5A93A7" fontSize={12} mr={2}>
                Tendencia
              </Text>
              <TrendLine data={trendDataNegative} negative />
            </Flex>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Box className="avatar">
                <img src="/static/avatar.png" />
              </Box>
              <Box ml={2} style={{ width: 64, textOverflow: 'ellipsis' }}>
                Bernardo
              </Box>
            </Flex>
            <ProgressBar width={230} progress={17} />
            <Flex alignItems="center">
              <Text color="#5A93A7" fontSize={12} mr={2}>
                Tendencia
              </Text>
              <TrendLine data={trendData} />
            </Flex>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Box className="avatar">
                <img src="/static/avatar.png" />
              </Box>
              <Box ml={2} style={{ width: 64, textOverflow: 'ellipsis' }}>
                Diego
              </Box>
            </Flex>
            <ProgressBar width={230} progress={5} />
            <Flex alignItems="center">
              <Text color="#5A93A7" fontSize={12} mr={2}>
                Tendencia
              </Text>
              <TrendLine data={trendDataNegative} negative />
            </Flex>
          </TableItem>
          <TableItem>
            <Flex alignItems="center">
              <Box className="avatar">
                <img src="/static/avatar.png" />
              </Box>
              <Box ml={2} style={{ width: 64, textOverflow: 'ellipsis' }}>
                Jorge
              </Box>
            </Flex>
            <ProgressBar width={230} progress={5} />
            <Flex alignItems="center">
              <Text color="#5A93A7" fontSize={12} mr={2}>
                Tendencia
              </Text>
              <TrendLine data={trendData} />
            </Flex>
          </TableItem>
        </TableList>
      </Card>
    </Box>
  </PrototypeWrapper>
);

export default Goals;
