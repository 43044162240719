const colors = {
  red: {
    light: '#C43004',
    dark: '#FC5C1F',
  },
  orange: {
    light: '#E96402',
    dark: '#D95300',
  },
  yellow: {
    light: '#D79500',
    dark: '#F9E923',
  },
  green: {
    light: '#29F58A',
    dark: '#16BC3D',
  },
  blue: {
    light: '#00EAED',
    dark: '#00B9F6',
  },
};

export const theme = {
  fontFamily: 'Boston',
};

export const lightTheme = {
  ...theme,
  bg: '#f9f9f9',
  colors: {
    primary: '#00c8c1',
    toolbar: { text: '#767D91', bg: 'rgba(0, 0, 0, 0.01)' },
    ...colors,
  },
};

export const darkTheme = {
  ...theme,
  bg: '#2e4566',
  colors: {
    primary: '#28EAEC',
    toolbar: { text: 'white', bg: 'rgba(0, 0, 0, 0.08)' },
    ...colors,
  },
};

export const getTheme = mode => (mode === 'dark' ? darkTheme : lightTheme);
