import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { Flex, Text } from '../../components/Base';
import { ArrowBack } from 'styled-icons/boxicons-regular/ArrowBack';
import ToggleMode from './ToggleMode';

const Wrapper = styled.div`
  border-radius: 18px;
  background: ${props =>
    props.theme.bg === '#f9f9f9' ? 'white' : props.theme.colors.toolbar.bg};
  color: ${props => props.theme.colors.primary};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  font-family: 'Montserrat';
`;

const FooterToolbar = ({ location }) => (
  <Wrapper>
    <div>
      {location.pathname !== '/' && (
        <Link to="/">
          <Text fontSize={16} color="primary">
            <ArrowBack style={{ width: 32 }} /> Volver al menú
          </Text>
        </Link>
      )}
    </div>
    <Flex alignItems="center">
      <Text as="span" mr="8px">
        Modo
      </Text>
      <ToggleMode />
    </Flex>
  </Wrapper>
);

export default withRouter(FooterToolbar);
