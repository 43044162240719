import { combineReducers } from 'redux';
import {
  FEEDBACK_SENT,
  FEEDBACK_SHOW,
  THEME_SET_MODE,
  USER_SET,
  FILTERS_SET,
  FILTERS_RESET,
} from '../actions';

const FiltersReducer = (state = { period: { label: 'Hoy' } }, action) => {
  switch (action.type) {
    case FILTERS_SET:
      return { ...state, ...action.filter };
    case FILTERS_RESET:
      return {};
    default:
      return state;
  }
};

const UserReducer = (state = { profile: { name: 'Sebastián' } }, action) => {
  switch (action.type) {
    case USER_SET:
      localStorage.setItem('username', action.user.name);
      localStorage.setItem('phone', action.user.email);
      return { ...state, profile: action.user };
    default:
      return state;
  }
};

const ThemeReducer = (state = { mode: 'dark' }, action) => {
  switch (action.type) {
    case THEME_SET_MODE:
      return { ...state, mode: action.mode };
    default:
      return state;
  }
};

const FeedbackReducer = (state = { opened: false, sent: false }, action) => {
  switch (action.type) {
    case FEEDBACK_SHOW:
      return { ...state, opened: action.opened };
    case FEEDBACK_SENT:
      return { ...state, sent: true };
    default:
      return state;
  }
};

const reducers = combineReducers({
  feedback: FeedbackReducer,
  theme: ThemeReducer,
  user: UserReducer,
  filters: FiltersReducer,
});

export default reducers;
