import stores from './stores';

export default {
  sales: {
    total: 200000,
    grow: 0.05,
    cash: 0.72,
    cards: 0.18,
    promo: 0.22,
    byTicket: {
      total: 150,
      grow: 0.05,
      avg: 1000,
      avgGrow: -0.04,
    },
    byUnits: {
      total: 450,
      grow: 3,
      avg: 3,
      avgGrow: 0.004,
      kgs: 460,
      kgsGrow: 0.02,
    },
    byStore: [
      { name: 'Córdoba', now: 12000, past: 8200 },
      { name: 'Carlos Paz', now: 6000, past: 5200 },
      { name: 'Cosquín', now: 4800, past: 2300 },
      { name: 'Alberdi', now: 4000, past: 3980 },
      { name: 'Rosario', now: 2000, past: 1900 },
      { name: 'San Luis', now: 1900, past: 1200 },
    ].sort((a, b) => b.now - a.now),
    distribuition: [
      {
        name: '9a',
        uv: 4000,
        pv: 2400,
        amt: 2400,
      },
      {
        name: '10a',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: '11a',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: '12p',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: '1p',
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: '2p',
        uv: 2390,
        uv2: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: '3p',
        uv: null,
        uv2: 3490,
        pv: 4300,
        amt: 2100,
      },
      {
        name: '4p',
        uv: null,
        uv2: 2300,
        pv: 3900,
        amt: 2100,
      },
      {
        name: '5p',
        uv: null,
        uv2: 4700,
        pv: 3900,
        amt: 2100,
      },
      {
        name: '6p',
        uv: null,
        uv2: 5600,
        pv: 5100,
        amt: 2100,
      },
      {
        name: '7p',
        uv: null,
        uv2: 7800,
        pv: 3900,
        amt: 2100,
      },
      {
        name: '8p',
        uv: null,
        uv2: 4500,
        pv: 3900,
        amt: 2100,
      },
    ],
  },
  goals: {},
  stock: {},
  demand: {},
};
