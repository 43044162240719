import React from 'react';
import styled from 'styled-components';
import { space, layout, color, flexbox } from 'styled-system';

const CardTitle = styled.div`
  font-size: 1rem;
  color: #748599;
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 16px;
  left: 24px;

  > svg {
    position: relative;
    top: -4px;
    margin-left: 1px;
  }

  span.icon {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.07);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
`;

export default CardTitle;
