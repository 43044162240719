import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { space, color } from 'styled-system';
import { Transition } from 'react-spring/renderprops';

import stores from '../../data/stores';
import { setFilter, resetFilters } from '../../actions';

import { ReactComponent as Day } from '../../icons/day.svg';
import { ReactComponent as Week } from '../../icons/week.svg';
import { ReactComponent as Month } from '../../icons/month.svg';
import { ReactComponent as Year } from '../../icons/year.svg';
import { ReactComponent as Store } from '../../icons/store.svg';

const FilterOptions = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  margin-top: 59px;
  padding-top: 19px;
  width: 100%;
  height: 70px;
  background: ${props => props.theme.bg};
  border: 2px solid ${props => props.theme.colors.primary};
  border-top: 0;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Boston';
  z-index: 1;

  button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    color: ${props => props.theme.colors.primary};
    appearance: none;
    background: none;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
    outline: 0;
    text-align: center;
    margin: 0 auto;

    span {
      line-height: 36px;
      white-space: nowrap;
    }

    &::after {
      content: '';
      border-radius: 3px 3px 0 0;
      height: 5px;
      margin: 0 auto;
      bottom: -1px;
      left: 32.5%;
      width: 35%;
      position: absolute;
    }

    svg {
      width: 20px;
      margin-right: 8px;
      top: -1px;
      position: relative;
    }

    &.active,
    &:hover {
      color: ${props => props.theme.colors.primary};
      background: rgba(55, 55, 55, 0.06);

      &::after {
        background: ${props => props.theme.colors.primary};
      }
    }
  }
`;

const FiltersContainer = styled.div`
  ${space}
  ${color}
  height: 125px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  position: relative;

  .buttons {
    position: relative;
    width: 100%;

    button {
      color: ${props => props.theme.colors.primary};
      background: ${props => props.theme.bg};
      border: 2px solid ${props => props.theme.colors.primary};
      border-radius: 8px;
      width: 49.5%;
      height: 80px;
      position: relative;
      z-index: 2;
      appearance: none;
      outline: 0;
      font-family: 'Montserrat';
      font-size: 32px;
      cursor: pointer;
      margin: 0 auto;

      &:active,
      &:focus {
        background: ${props => props.theme.bg};
      }
    }
  }

  hr {
    width: 100%;
    position: absolute;
    top: 109px;
    left: 0;
    height: 2px;
    border: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 1138px) and (max-height: 712px) {
    height: 100px;
    margin-top: 12px;
    hr {
      display: none;
    }
  }
`;

const periods = [
  { name: 'Hoy', multi: 1 },
  { name: 'Semanal', multi: 1.0156 },
  { name: 'Mensual', multi: 1.0625 },
  { name: 'Anual', multi: 1.75 },
];

function Filters() {
  const dispatch = useDispatch();
  const { store, period } = useSelector(store => store.filters);
  const [showOptions, setOptions] = useState(0);

  useEffect(() => {
    setOptions(0);
  }, [store, period]);

  const setPeriod = period => {
    dispatch(setFilter({ period }));
  };

  const setStore = store => {
    dispatch(setFilter({ store }));
  };

  return (
    <FiltersContainer>
      <div className="buttons">
        <button
          onClick={() => setOptions(showOptions === 1 ? 0 : 1)}
          style={{ marginRight: '1%' }}
        >
          {period.label}
        </button>
        <button onClick={() => setOptions(showOptions === 2 ? 0 : 2)}>
          {store ? store.name : 'Todos'}
        </button>
      </div>
      <hr />
      <Transition
        items={showOptions}
        from={{ transform: 'translate3d(0,-40px, 0)', opacity: 0 }}
        enter={{ transform: 'translate3d(0,0px, 0)', opacity: 1 }}
        leave={{ transform: 'translate3d(0,-40px, 0)', opacity: 0 }}
      >
        {showOptions =>
          showOptions &&
          (styles => {
            if (showOptions === 2) {
              return (
                <FilterOptions style={styles}>
                  {stores.map((s, idx) => (
                    <button
                      style={{ width: `${100 / stores.length}%` }}
                      className={
                        store ? store.name === s.name && 'active' : null
                      }
                      onClick={() =>
                        store && store.name === s.name
                          ? setStore(null)
                          : setStore(s)
                      }
                    >
                      <Store />
                      <span>{s.name}</span>
                    </button>
                  ))}
                </FilterOptions>
              );
            }
            if (showOptions === 1) {
              return (
                <FilterOptions style={styles}>
                  <button
                    className={period.label === 'Hoy' && 'active'}
                    onClick={() =>
                      setPeriod({ label: 'Hoy', mult: 1 })
                    }
                  >
                    <Day />
                    <span>Hoy</span>
                  </button>
                  <button
                    className={
                      period.label === 'Semanal' && 'active'
                    }
                    onClick={() =>
                      setPeriod({
                        label: 'Semanal',
                        mult: 11.50156,
                      })
                    }
                  >
                    <Week />
                    <span>Semanal</span>
                  </button>
                  <button
                    className={
                      period.label === 'Mensual' && 'active'
                    }
                    onClick={() =>
                      setPeriod({
                        label: 'Mensual',
                        mult: 11.50156 * 3.0625,
                      })
                    }
                  >
                    <Month />
                    <span>Mensual</span>
                  </button>
                  <button
                    className={
                      period.label === 'Anual' && 'active'
                    }
                    onClick={() =>
                      setPeriod({
                        label: 'Anual',
                        mult: 52 * 0.65 * 11.50156,
                      })
                    }
                  >
                    <Year />
                    <span>Anual</span>
                  </button>
                </FilterOptions>
              );
            }
          })
        }
      </Transition>
    </FiltersContainer>
  );
}

export default Filters;
