import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from '@smooth-ui/core-sc';

import { changeThemeMode } from '../../actions';
import { ReactComponent as DarkOn } from '../../icons/dark_on.svg';
import { ReactComponent as DarkOff } from '../../icons/dark_off.svg';

const Switch2 = styled(Switch)`
  height: 28px;

  input {
    cursor: pointer;
  }

  .sui-switch-wrapper {
    border: 2px solid #e6e6e6;
    height: 28px;
    background: transparent;
  }

  .sui-switch-ball {
    background: #e6e6e6;
  }
  .sui-switch-content {
    height: 24px;
  }
  .sui-switch-label-on {
    margin-top: 7px;
  }
  .sui-switch-label-off {
    margin-top: 7px;
    margin-right: 1px;
  }

  input:checked {
    & + .sui-switch-wrapper {
      background: transparent;
      border: 2px solid #00eaed;

      .sui-switch-content {
        transform: translateX(-2px);
      }
      .sui-switch-ball {
        background: #00eaed;
      }
    }
  }
`;

export default function ToggleMode() {
  const dispatch = useDispatch();
  const mode = useSelector(state => state.theme.mode);

  const changeMode = () => {
    dispatch(changeThemeMode(mode === 'light' ? 'dark' : 'light'));
  };
  return (
    <Switch2
      labeled
      onLabel={<DarkOn />}
      offLabel={<DarkOff />}
      onChange={changeMode}
      checked={mode === 'dark'}
    />
  );
}
