import React from 'react';
import { useSelector } from 'react-redux';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
  withRouter,
} from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import './App.css';
import Login from './Login';
import fakeAuth from './fakeAuth';
// import DashboardJSON from "./data/dashboard";
import Feedback from './Feedback';
import Dashboard from './Prototype/Dashboard';
import Sales from './Prototype/Sales';
import Goals from './Prototype/Goals';
import Stock from './Prototype/Stock';
import Demand from './Prototype/Demand';
import { getTheme } from './theme';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      fakeAuth.isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const AuthButton = withRouter(({ history }) =>
  fakeAuth.isAuthenticated ? (
    <p>
      Welcome!{' '}
      <button
        onClick={() => {
          fakeAuth.signout(() => history.push('/'));
        }}
      >
        Sign out
      </button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  )
);

const GlobalStyle = createGlobalStyle`
  :root {
    --bg-color: ${props => props.theme.bg};
  }

  body {
    color: ${props => (props.whiteColor ? 'white' : 'black')};
    font-family: ${props => props.theme.fontFamily};
    font-weight: 600;
  }
`;

function App() {
  const mode = useSelector(store => store.theme.mode);

  return (
    <ThemeProvider theme={getTheme(mode)}>
      <>
        <GlobalStyle />
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute path="/ventas" component={Sales} />
            <PrivateRoute path="/objetivos" component={Goals} />
            <PrivateRoute path="/stock" component={Stock} />
            <PrivateRoute path="/demanda" component={Demand} />
          </Switch>
          <Feedback />
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
