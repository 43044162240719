import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Box, Text } from '../../components/Base';

const getThemeColor = ({ theme, progress }) => {
  if (progress > 100) return theme.colors.blue;
  if (progress >= 80) return theme.colors.green;
  if (progress >= 50) return theme.colors.yellow;
  if (progress >= 25) return theme.colors.orange;
  else return theme.colors.red;
};

const Progress = styled(Box)`
  position: relative;
  border-radius: 8px;
  background: #4d7691;
  ${props =>
    props.labelTop
      ? css`
          height: 6px;
        `
      : css`
          height: 9px;
          margin-right: 8px;
        `};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    background: ${props => `linear-gradient(
      to left,
      ${props.noColor ? '#48D6D4' : getThemeColor(props).light},
      ${props.noColor ? '#39A273' : getThemeColor(props).dark}
    )`};
    transition: all 0.4s linear;
    border-radius: 8px;
    width: ${props => `${props.progress > 100 ? 100 : props.progress}%`};
    height: inherit;
  }
`;

const ProgressLabel = ({ progress, ...rest }) => (
  <Text style={{ whiteSpace: 'nowrap' }} {...rest}>{`${progress}%`}</Text>
);

const ProgressBar = ({ progress, labelTop, noColor, noLabel, ...rest }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    flexDirection={labelTop && 'column'}
    {...rest}
  >
    {!noLabel && labelTop && (
      <ProgressLabel
        progress={progress}
        textAlign="right"
        width={1}
        fontSize={11}
        color="white"
        mb="2px"
      />
    )}
    <Progress
      width={labelTop ? 1 : 0.85}
      progress={progress}
      labelTop={labelTop}
      noColor={noColor}
    />
    {!noLabel && !labelTop && (
      <ProgressLabel progress={progress} width={0.15} />
    )}
  </Flex>
);

export default ProgressBar;
