import React from 'react';
import { format as d3format } from 'd3-format';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import PrototypeWrapper from './Wrapper';
import {
  BarChart,
  Bar,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Flex, Box, Text } from '../components/Base';
import Card from '../components/Card';
import CardTitle from './components/CardTitle';
import Highlight from './components/Highlight';
import { DiffNumber, DiffBox } from './components/Diff';

import { ReactComponent as SalesIcon } from '../icons/money.svg';
import { ReactComponent as MoneySign } from '../icons/money-3.svg';
import { ReactComponent as MoneyBill } from '../icons/money-bill.svg';
import { ReactComponent as Ticket } from '../icons/ticket.svg';
import { ReactComponent as BoxIcon } from '../icons/box.svg';
import { ReactComponent as UnitKg } from '../icons/unit-kg.svg';
import { ReactComponent as CardIcon } from '../icons/card.svg';
import { ReactComponent as CouponIcon } from '../icons/coupon.svg';
import { ReactComponent as ClockIcon } from '../icons/clock.svg';

import data from '../data';

const legends = {
  pv: 'Anterior',
  uv: 'Actual',
  uv2: 'Proyectado',
};

const legends2 = {
  pv: 'Ventas',
  uv: 'Objetivos',
  amt: 'Anterior',
};

const colors = ['#84A7EB', '#A5DC72', '#84C99E', '#D0EB62', '#8886D6'];

const currency = number =>
  new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

const percentage = number =>
  new Intl.NumberFormat('es-AR', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

const decimal = number =>
  new Intl.NumberFormat('es-AR', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

function Sales() {
  const { period, store } = useSelector(store => store.filters);

  const getData = data =>
    data * (period.mult || 1) * ((store && store.share) || 1);

  return (
    <PrototypeWrapper>
      <Box className="boxes">
        <Card border="#00C6FF" animation={{ delay: 0 }} px="32px">
          <CardTitle>
            <span className="icon" style={{ color: '#00C6FF' }}>
              <SalesIcon />
            </span>
            Ventas
          </CardTitle>
          <Box width={1} style={{ textAlign: 'center' }}>
            <Flex alignItems="center" justifyContent="center">
              <Box style={{ display: 'inline-block', position: 'relative' }}>
                <Text
                  fontSize="16px"
                  color="#748599"
                  lineHeight={1}
                  style={{ position: 'absolute', right: 0 }}
                >
                  Monto
                </Text>
                <Text fontSize={64} lineHeight={1.4} textAlign="center">
                  {currency(getData(data.sales.total))}
                </Text>
              </Box>
              <DiffBox
                fontSize={17}
                number={getData(data.sales.grow)}
                ml={24}
              />
            </Flex>
            <Flex justifyContent="space-around" mt="8px" width={0.87} mx="auto">
              <Highlight>
                <div className="icon">
                  <MoneyBill />
                </div>
                <Box width={1}>
                  <div className="title">Efectivo</div>
                  <Flex className="values">
                    <div>
                      {currency(getData(data.sales.total) * data.sales.cash)}
                    </div>
                  </Flex>
                </Box>
              </Highlight>
              <Highlight>
                <div className="icon">
                  <CardIcon />
                </div>
                <Box width={1}>
                  <div className="title">Tarjetas</div>
                  <Flex className="values">
                    <div>
                      {currency(getData(data.sales.total) * data.sales.cards)}
                    </div>
                  </Flex>
                </Box>
              </Highlight>
              <Highlight>
                <div className="icon">
                  <CouponIcon />
                </div>
                <Box width={1}>
                  <div className="title">Descuentos</div>
                  <Flex className="values">
                    <div>
                      {currency(getData(data.sales.total) * data.sales.promo)}
                    </div>
                  </Flex>
                </Box>
              </Highlight>
            </Flex>
          </Box>
        </Card>
        <Card border="#00C6FF" animation={{ delay: 200 }} px="32px">
          <CardTitle>
            <span className="icon" style={{ color: '#00C6FF' }}>
              <SalesIcon />
            </span>
            Distribución de Ventas
          </CardTitle>
          <Flex width={1} height="100%">
            <Flex
              width={0.2}
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              pt={2}
            >
              <ClockIcon />
              <Text fontSize={16} color="#748599">
                Hora Pico
              </Text>
              <Text fontSize={[28, 28, 28, 28, 36]} fontWeight={300} mt={16}>
                18:00hs
              </Text>
            </Flex>
            <Box width={0.8} height="100%" p="44px 0 16px 20px">
              <ResponsiveContainer style={{ marginTop: 56 }}>
                <LineChart
                  data={data.sales.distribuition}
                  style={{
                    borderRadius: 10,
                    background: '#3C5C7A',
                    boxShadow: '1px 1px 9px 2px rgba(0, 0, 0, 0.09)',
                  }}
                  margin={{ top: -32, right: 10, bottom: 0, left: -16 }}
                >
                  <Legend
                    verticalAlign="top"
                    align="right"
                    height={48}
                    iconType="plainline"
                    formatter={(value, entry) => (
                      <Text as="span" color="#8699AD" fontSize={11}>
                        {legends[value]}
                      </Text>
                    )}
                  />
                  <CartesianGrid
                    stroke="rgba(0,0,0,0.13)"
                    strokeWidth={0.5}
                    style={{ padding: '0.8rem' }}
                  />
                  <XAxis
                    dataKey="name"
                    stroke="rgba(0,0,0,0.04)"
                    interval={0}
                    width={30}
                    tick={({ x, y, payload }) => (
                      <g transform={`translate(${x}, ${y + 5})`}>
                        <text
                          x={0}
                          y={0}
                          dy={3}
                          textAnchor="middle"
                          fill="#8699AD"
                          style={{
                            fontSize: 10,
                          }}
                        >
                          {payload.value}
                        </text>
                      </g>
                    )}
                  />
                  <YAxis
                    stroke="rgba(0,0,0,0.04)"
                    tick={({ x, y, payload }) => (
                      <g transform={`translate(${x},${y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={3}
                          textAnchor="end"
                          fill="#8699AD"
                          style={{
                            textTransform: 'uppercase',
                            fontSize: 11,
                          }}
                        >
                          {d3format('.2s')(payload.value)}
                        </text>
                      </g>
                    )}
                  />
                  {/* <Tooltip /> */}
                  <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#4D7691"
                    strokeWidth={2}
                    activeDot={{ r: 8 }}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="uv"
                    stroke="#2BE658"
                    strokeWidth={2}
                    dot={false}
                  />
                  <Line
                    type="monotone"
                    dataKey="uv2"
                    stroke="#2BE658"
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </Flex>
        </Card>
        <Card border="#00C6FF" animation={{ delay: 400 }} px="32px">
          <CardTitle>
            <span className="icon" style={{ color: '#00C6FF' }}>
              <SalesIcon />
            </span>
            Métricas
          </CardTitle>
          <Box width={0.87} mt="20px">
            <Flex justifyContent="space-around">
              <Highlight>
                <div className="icon">
                  <MoneySign />
                </div>
                <Box width={1}>
                  <div className="title">Monto</div>
                  <Flex className="values">
                    <div>{currency(getData(data.sales.total))}</div>
                    <DiffNumber number={getData(data.sales.grow)} />
                  </Flex>
                </Box>
              </Highlight>
              <Highlight>
                <div className="icon">
                  <Ticket />
                </div>
                <Box width={1}>
                  <div className="title">Tickets</div>
                  <Flex className="values">
                    <div>{decimal(getData(data.sales.byTicket.total))}</div>
                    <DiffNumber number={getData(data.sales.byTicket.grow)} />
                  </Flex>
                </Box>
              </Highlight>
              <Highlight>
                <div className="icon">
                  <BoxIcon />
                </div>
                <Box width={1}>
                  <div className="title">Unidades</div>
                  <Flex className="values">
                    <div>{decimal(getData(data.sales.byUnits.total))}</div>
                    <DiffNumber number={getData(data.sales.byUnits.grow)} />
                  </Flex>
                </Box>
              </Highlight>
            </Flex>
            <Flex justifyContent="space-around" mt={3}>
              <Highlight>
                <div className="icon">
                  <UnitKg />
                </div>
                <Box width={1}>
                  <div className="title">Kilos</div>
                  <Flex className="values">
                    <div>{decimal(getData(data.sales.byUnits.kgs))}</div>
                    <DiffNumber number={getData(data.sales.byUnits.kgsGrow)} />
                  </Flex>
                </Box>
              </Highlight>
              <Highlight>
                <div className="icon">
                  <Ticket />
                </div>
                <Box width={1}>
                  <div className="title">Ticket Prom.</div>
                  <Flex className="values">
                    <div>{decimal(Math.round(getData(data.sales.byTicket.avg)))}</div>
                    <DiffNumber number={getData(data.sales.byTicket.avgGrow)} />
                  </Flex>
                </Box>
              </Highlight>
              <Highlight>
                <div className="icon">
                  <BoxIcon />
                </div>
                <Box width={1}>
                  <div className="title">Un. Prom.</div>
                  <Flex className="values">
                    <div>{decimal(getData(data.sales.byUnits.avg))}</div>
                    <DiffNumber number={getData(data.sales.byUnits.avgGrow)} />
                  </Flex>
                </Box>
              </Highlight>
            </Flex>
          </Box>
        </Card>
        <Card border="#00C6FF" animation={{ delay: 600 }} px="32px">
          <CardTitle>
            <span className="icon" style={{ color: '#00C6FF' }}>
              <SalesIcon />
            </span>
            Venta sucursales
          </CardTitle>
          <Box width={0.92} height="100%" p="44px 0 16px 20px">
            <ResponsiveContainer style={{ marginTop: 56 }}>
              <BarChart
                data={data.sales.byStore.map(d => ({
                  ...d,
                  now: getData(d.now),
                  past: getData(d.past),
                }))}
                style={{
                  borderRadius: 10,
                  background: '#3C5C7A',
                  boxShadow: '1px 1px 9px 2px rgba(0, 0, 0, 0.09)',
                }}
                margin={{ top: -32, right: 10, bottom: 0, left: -16 }}
                barGap={0}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="-15%" stopColor="#00D6FF" stopOpacity={1} />
                    <stop offset="125%" stopColor="#0097FF" stopOpacity={1} />
                  </linearGradient>
                </defs>
                <CartesianGrid
                  stroke="rgba(0,0,0,0.13)"
                  strokeWidth={0.5}
                  style={{ padding: '0.8rem' }}
                />
                <Legend
                  verticalAlign="top"
                  align="right"
                  height={48}
                  iconType="square"
                  formatter={(value, entry) => (
                    <Text as="span" color="#8699AD" fontSize={11}>
                      {legends2[value]}
                    </Text>
                  )}
                />
                <XAxis
                  dataKey="name"
                  stroke="rgba(0,0,0,0.04)"
                  interval={0}
                  width={30}
                  tick={({ x, y, payload }) => (
                    <g transform={`translate(${x}, ${y + 5})`}>
                      <text
                        x={0}
                        y={0}
                        dy={3}
                        textAnchor="middle"
                        fill="#8699AD"
                        style={{
                          fontSize: 10,
                        }}
                      >
                        {payload.value}
                      </text>
                    </g>
                  )}
                />
                <YAxis
                  stroke="rgba(0,0,0,0.04)"
                  tick={({ x, y, payload }) => (
                    <g transform={`translate(${x},${y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={3}
                        textAnchor="end"
                        fill="#8699AD"
                        style={{
                          textTransform: 'uppercase',
                          fontSize: 11,
                        }}
                      >
                        {d3format('.2s')(payload.value)}
                      </text>
                    </g>
                  )}
                />
                <Bar
                  dataKey="now"
                  radius={[3, 3, 0, 0]}
                  barSize={22}
                  fill="url(#colorUv)"
                >
                  {data.sales.byStore.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill="url(#colorUv)" />
                  ))}
                </Bar>
                <Bar
                  dataKey="past"
                  fill="#4D7691"
                  radius={[3, 3, 0, 0]}
                  barSize={22}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Card>
      </Box>
    </PrototypeWrapper>
  );
}

export default Sales;
