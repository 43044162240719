import React from 'react';
import styled from 'styled-components';
import { Flex } from '../../components/Base';

const Highlight = styled(Flex)`
  background: #3c5c7a;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  line-height: 1;
  padding: 6px 8px 6px 6px;
  align-items: center;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
  min-width: 155px;

  .title {
    color: ${props => props.color};
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.8px;
    text-align: left;
    margin-bottom: 4px;
  }

  .values {
    align-items: center;
    justify-content: space-between;
  }

  .icon {
    color: ${props => props.color};
    background: #35516d;
    border-radius: 7px;
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  .diff {
    font-size: 13px;
    color: #00ff00;
    margin-left: 8px;

    &.negative {
      color: ${props => props.theme.colors.red.dark};
    }
  }
`;

Highlight.defaultProps = {
  color: '#55c6ff',
};

export default Highlight;
