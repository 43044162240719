import React from 'react';
import styled from 'styled-components';
import { Box } from '../components/Base';
import MainToolbar from './components/MainToolbar';
import Filters from './components/Filters';
import FooterToolbar from './components/FooterToolbar';

const Container = styled.div`
  max-width: 1500px;
  padding: 16px;
  margin: 0 auto;
  display: flex;
  background: ${props => props.theme.bg};

  .boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    padding: 16px 0;

    /* @media screen and (max-width: 690px) {
      grid-template-columns: repeat(1, 1fr);
    } */
    a {
      display: contents;
      /* width: 100%; */
    }
  }
`;

export default function Prototype({ children }) {
  return (
    <Container>
      <Box width={1}>
        <MainToolbar />
        <Filters />
        {children}
        {/* <FooterToolbar /> */}
      </Box>
    </Container>
  );
}
