import React from 'react';
import { LineChart, Line } from 'recharts';
import { Box } from '../../components/Base';

const TrendLine = ({ data, negative }) => (
  <Box
    style={{
      background: '#2F4A60',
      borderRadius: 6,
      // padding: '2px',
      // boxShadow: '1px 1px 9px 2px rgba(0, 0, 0, 0.09)',
    }}
  >
    <LineChart width={56} height={26} data={data}>
      <Line
        type="monotone"
        dataKey="pv"
        stroke={negative ? '#C94216' : '#34E176'}
        strokeWidth={2}
        dot={({ index, cx, cy, stroke, payload, value }) =>
          index === data.length - 1 && (
            <svg x={cx - 3} y={cy - 2} style={{ overflow: 'visible' }}>
              <circle cx={2} cy={2} r={2} fill={stroke} />
            </svg>
          )
        }
      />
    </LineChart>
  </Box>
);

export default TrendLine;
